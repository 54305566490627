export const AccordionFaqList = () => {
  document.addEventListener("DOMContentLoaded", function () {
    const faqItems = document.querySelectorAll(".faq-item");

    faqItems.forEach((item) => {
      const question = item.querySelector(".faq-item__question");
      const answer = item.querySelector(".faq-item__answer");
      const arrow = item.querySelector(".faq-item__arrow");

      question.addEventListener("click", () => {
        answer.classList.toggle("active");
        if (answer.classList.contains("active")) {
          answer.style.display = "block";
          arrow.classList.add("faq-item__arrow--top");
          arrow.classList.remove("faq-item__arrow--bottom");
        } else {
          answer.style.display = "none";
          arrow.classList.add("faq-item__arrow--bottom");
          arrow.classList.remove("faq-item__arrow--top");
        }
      });
    });
  });
};
