import "@scss/style.scss";
import { GlobalContentFixed } from "@js/globalContentFixed.js";

const contentFixed = new GlobalContentFixed();
const menuButton = document.querySelector('[data-target="jsMenuButton"]');
const menuContent = document.querySelector('[data-target="jsMenuContent"]');
const menuLink = document.querySelectorAll('[data-target="jsMenuLink"]');
const menuClass = "header-nav--open";
const buttonClass = "header-menu-button--open";

const GlobalMenuFunc = () => {
  menuButton.addEventListener("click", () => {
    if (menuContent.classList.contains(menuClass)) {
      contentFixed.unfix();
      menuContent.classList.remove(menuClass);
      menuButton.classList.remove(buttonClass);
    } else {
      contentFixed.fix();
      menuContent.classList.add(menuClass);
      menuButton.classList.add(buttonClass);
    }
  });

  menuLink.forEach((link) => {
    link.addEventListener("click", () => {
      if (menuContent.classList.contains(menuClass)) {
        contentFixed.unfix();
        menuContent.classList.remove(menuClass);
        menuButton.classList.remove(buttonClass);
      }
    });
  });
};

export const GlobalMenu = () => {
  if (menuButton) {
    GlobalMenuFunc();
  }
};
