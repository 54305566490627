export class GlobalContentFixed {
  constructor() {
    this.body = document.body;
    this.currentScrollY = "";
  }

  _getTop() {
    return document.documentElement.scrollTop || this.body.scrollTop;
  }

  fix() {
    this.currentScrollY = this._getTop();
    this.body.classList.add("scroll-prevent");
  }

  unfix() {
    this.body.classList.remove("scroll-prevent");
    const WINDOW_TOP = 0;
    this.body.style.top = WINDOW_TOP;
    window.scroll(0, this.currentScrollY);
  }
}
